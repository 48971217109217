import React from "react";
import { graphql, Link } from "gatsby";

import Layout from "../components/layout";
import Seo from "../components/seo";

class NotFoundPage extends React.Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <Seo title="404: Not Found" />
        <h1>Not Found</h1>
        <p>
          Looks like you've followed a broken link or entered a URL that doesn't
          exist on this site.
        </p>
        <p>
          <Link href="/">&laquo; Back to the only page on this site</Link>
        </p>
      </Layout>
    );
  }
}

export default NotFoundPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
